import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto" }
const _hoisted_2 = { class: "text-center mb-10" }
const _hoisted_3 = { class: "text-dark mb-3" }
const _hoisted_4 = { class: "fv-row mb-10" }
const _hoisted_5 = { class: "form-label fs-6 fw-bolder text-dark" }
const _hoisted_6 = { class: "fv-plugins-message-container" }
const _hoisted_7 = { class: "fv-help-block" }
const _hoisted_8 = { class: "fv-row mb-10" }
const _hoisted_9 = { class: "d-flex flex-stack mb-2" }
const _hoisted_10 = { class: "form-label fw-bolder text-dark fs-6 mb-0" }
const _hoisted_11 = { class: "fv-plugins-message-container" }
const _hoisted_12 = { class: "fv-help-block" }
const _hoisted_13 = { class: "text-center" }
const _hoisted_14 = {
  type: "submit",
  ref: "submitButton",
  id: "kt_sign_in_submit",
  class: "btn btn-lg btn-primary w-100 mb-5"
}
const _hoisted_15 = { class: "indicator-label" }
const _hoisted_16 = { class: "indicator-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      class: "form w-100",
      id: "kt_login_signin_form",
      onSubmit: _ctx.onSubmitLogin,
      "validation-schema": _ctx.login
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("general.welcome")), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-gray-400 fw-bold fs-4" }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("auth.email")), 1),
          _createVNode(_component_Field, {
            class: "form-control form-control-lg form-control-solid",
            type: "text",
            name: "email",
            autocomplete: "off"
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ErrorMessage, { name: "email" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("auth.password")), 1),
            _createVNode(_component_router_link, {
              to: "/password-reset",
              class: "link-primary fs-6 fw-bolder"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("auth.forgotPassword")) + " ? ", 1)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_Field, {
            class: "form-control form-control-lg form-control-solid",
            type: "password",
            name: "password",
            autocomplete: "off"
          }),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_ErrorMessage, { name: "password" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("auth.signIn")), 1),
            _createElementVNode("span", _hoisted_16, [
              _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
            ])
          ], 512)
        ])
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema"])
  ]))
}